const headers = [
  {
    text: 'Posición',
    value: 'order',
    sortable: false
  },
  {
    text: 'Nombre Actividad',
    value: 'name',
    sortable: false
  },
  {
    text: 'Fechas',
    value: 'dates',
    sortable: false
  },
  {
    text: 'Horarios',
    value: 'schedules',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false,
    width: '15%'
  },
  {
    text: 'Activo',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '15%'
  }
]
export default headers
