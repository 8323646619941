import DatePicker from '@/components/date-picker/DatePicker'
import moment from 'moment'

const dateTimeMixin = {
  components: {
    DatePicker
  },
  data () {
    return {
      times: []
    }
  },
  methods: {
    getTimes () {
      const times = []
      for (let i = 6; i < 21; i++) {
        let j = 0
        while (j < 60) {
          const hour = i.toString().length === 2 ? `${i}` : `0${i}`
          const min = j.toString().length === 2 ? `${j}` : `0${j}`
          const time = `${hour}:${min}:00`
          const description = moment(time, 'HH:mm').format('hh:mm A')
          times.push({
            description,
            time
          })
          j += 5
        }
      }
      this.times = times
    }
  },
  created () {
    this.getTimes()
  }
}
export default dateTimeMixin
